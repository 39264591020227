<template>
  <div class="viewOnlineExam">
    <v-row>
      <v-col cols="12">
        <div class="title">
          <h1>{{ ExamData.class_name }}</h1>
        </div>
        <div class="title">
          <h2 class="main-color" v-if="ExamData.subject_name != ''">
            {{ ExamData.subject_name }} - {{ ExamData.exam_name }}
          </h2>
          <h2 class="main-color" v-else>
            {{ ExamData.exam_name }}
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10" class="info-list">
        <ul class="inline">
          <li>
            <strong> {{ $t("Date") }}: </strong>{{ ExamData.exam_date }}
          </li>
          <li>
            <strong> {{ $t("Duration") }}: </strong
            >{{ ExamData.duration_hr }}:{{ ExamData.duration_min }}
          </li>
          <li v-if="ExamData.year_name != ''">
            <strong> {{ $t("Academic Period") }}:</strong>
            {{ ExamData.year_name }}
          </li>
          <li v-if="ExamData.qualifier_name != ''">
            <strong> {{ $t("Qualifier") }}:</strong>
            {{ ExamData.qualifier_name }}
          </li>
          <li>
            <strong> {{ $t("Marks") }}:</strong> {{ ExamData.total_mark }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="2" class="icons-div text-right">
        <v-icon
          class="main-color"
          @click="editExam()"
          v-if="!confirmed && loaded"
          large
          >edit</v-icon
        >
      </v-col>
      <v-col cols="12" md="12" class="hr-div">
        <hr />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        class="sectionBody"
        v-for="(section, index) in sectionsData['sections']"
        :key="index"
      >
        <v-row>
          <v-col cols="12" sm="12">
            <p class="section-title main-color">
              {{ section.section_title }}
            </p>
          </v-col>
        </v-row>
        <v-row v-for="(question, qindex) in section['questions']" :key="qindex">
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="6" sm="10">
                <p class="text-left question">
                  <strong
                    v-html="question.question"
                    class="answer-img-container"
                  >
                  </strong>
                </p>
              </v-col>
              <v-col cols="6" sm="2">
                <p class="text-right main-color">
                  {{ $t("Marks") }}: {{ question.Marks }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" style="padding-top: 0">
            <p
              v-if="question.type == 'essay'"
              style="color: green"
              v-html="question.answer"
            ></p>
            <!-- mcq -->
            <v-radio-group
              v-model="question.answer"
              v-if="question.type == 'tof' || question.type == 'mcq'"
              row
              readonly
            >
              <v-radio
                v-for="(option, Oindex) in question.options"
                :key="Oindex"
                label=""
                :value="option.value"
                readonly
                color="green"
              >
                <template slot="label">
                  <p class="answer-img-container" v-html="$t(option.value)"></p>
                </template>
              </v-radio>
            </v-radio-group>
            <!-- join -->
            <div v-if="question.type == 'join'">
              <v-row v-for="(join, Jindex) in question.joins" :key="Jindex">
                <v-col cols="2">
                  <p
                    :hidden="
                      join.join_question == '' || join.join_question == null
                    "
                  >
                    {{ join.join_question_number }} . {{ join.join_question }}
                  </p>
                </v-col>
                <v-col cols="2">
                  <div
                    :hidden="
                      join.join_question == '' || join.join_question == null
                    "
                  >
                    <v-select
                      :items="join.all_answers_items"
                      solo
                      v-model="join.correct_answer"
                      readonly
                      color="green"
                    >
                    </v-select>
                  </div>
                </v-col>
                <v-col cols="2">
                  <p>{{ join.join_answer_number }} . {{ join.join_answer }}</p>
                </v-col>
              </v-row>
            </div>
            <hr />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" class="text-right">
        <v-btn
          class="ui-btn submit text-right"
          @click.prevent="confirmExam()"
          v-if="!confirmed && examSaved && loaded"
          :loading="loading"
          :disabled="loading"
          large
        >
          <!-- @click.prevent="" -->
          {{ $t("confirm") }}
        </v-btn>
        <v-btn
          class="ui-btn submit text-right"
          @click.prevent="unconfirmExam()"
          v-if="confirmed && examSaved && loaded"
          :loading="loading"
          :disabled="loading"
          large
        >
          <!-- @click.prevent="" -->
          {{ $t("Unconfirm") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "viewOnlineExam",
  data() {
    return {
      loading: false,
      snack: false,
      snackText: "",
      snackColor: "",
      snackTime: 3000,
      ExamData: {},
      sectionsData: [],
      examSaved: "",
      confirmed: "",
      loaded: false,
      arabic_exam: false,
    };
  },
  methods: {
    getExamData() {
      axios
        .get(this.getApiUrl + "/exams/show/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.ExamData = response.data.data;

          this.examSaved = this.ExamData.saved;
          this.confirmed = this.ExamData.confirmed;
          this.arabic_exam = this.ExamData.is_arabic;

          // if (this.arabic_exam) {
          //   if (this.currentAppLocale == "en") {
          //     localStorage.locale = "ar";
          //     window.location.reload();
          //   }
          // }
          this.loaded = true;
        });

      axios
        .get(this.getApiUrl + "/onlineexams/show/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.sectionsData = response.data.data;
        });
    },
    editExam() {
      this.$router.push({
        path: "/createOnlineExam/" + this.examId + "?edit=true",
      });
    },
    confirmExam() {
      let marks = 0;
      this.sectionsData["sections"].forEach((section) => {
        section.questions.forEach((question) => {
          if (question.Marks == 0) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = this.$i18n.t("Question Mark shouldnot be 0");
          }
          marks += parseFloat(question.Marks);
        });
      });
      if (!this.snack) {
        // console.log(marks);
        if (parseFloat(marks) != parseFloat(this.ExamData.total_mark)) {
          this.snack = true;
          this.snackColor = "red";
          this.snackText = this.$i18n.t(
            "Sum of questions mark  should be equal to exam total marks."
          );
        } else {
          this.loading = true;
          axios
            .get(this.getApiUrl + "/onlineexams/confirmed/" + this.examId, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == false) {
                this.getExamData();
                // this.$router.push({
                //   path: "/viewOnlineExam/" + this.classId + "/" + this.examId
                // });
              }
            });
        }
      }
    },
    unconfirmExam() {
      this.loading = true;
      axios
        .get(this.getApiUrl + "/onlineexams/unconfirmed/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.getExamData();
          }
        });
    },
  },
  mounted() {
    this.$forceUpdate();
    this.classId = this.$router.currentRoute.params.classId;
    this.examId = this.$router.currentRoute.params.examId;
    this.getExamData();
    setTimeout(function () {
      console.log("rendering mathjax");
      if (window.MathJax != undefined) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 20%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.sectionBody {
  box-shadow: $box-shadow;
  margin-top: 1rem;
}
.question {
  margin-bottom: 0;
}
.section-title {
  font-size: 1.4rem;
}
</style>
